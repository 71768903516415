.experience {
    margin-top: 5%;
    background-image: url(../../Media/hover_img.jpg);
    background-position: center left;
    background-repeat: no-repeat;
    background-size: auto;
}

.experience .years {
    padding: 90px 0px 140px 0px;
}

.experience .num {
    font-size: 156px;
    color: var(--light);
}

.experience .years-text {
    color: var(--header-color);
    font-size: 24px;
    font-weight: 500;
    white-space: nowrap;
    margin-bottom: 10px;
}

.experience .info-text {
    color: var(--header-color);
    font-size: 36px;
    font-weight: 600;
    width: 90%;
}

.experience .info-text span {
    color: var(--light);
}

.experience .header-two {
    font-weight: 600;
    font-size: 36px;
    color: var(--header-color);
}

.experience .side-text {
    padding: 120px 0px 140px 100px;
}

@media (max-width: 768px) {
    .experience {
        padding: 20px;
        padding-bottom: 0;
        padding-top: 0px;
    }

    .experience .info-text {
        width: 100%;
        font-size: 34px;
    }

    .experience .years {
        padding: 0;
        margin: 0;
    }

    .experience .side {
        margin-top: 80px;
    }

    .experience .header-two {
        font-size: 32px;
    }

    .experience .side-text {
        padding-left: 30px;
    }
}

@media (max-width: 480px) {
    .experience {
        padding: 70px 10px;
        padding-top: 0;
        background-size: cover;
        margin-top: 0;
    }

    .experience .info-text {
        width: 100%;
        /* font-size: 34px; */
    }

    .experience .years {
        padding: 0px;
    }

    .experience .years-text {
        margin-bottom: 10px !important;
    }

    .experience .side-text {
        padding: 0px;
        padding-left: 10px;
    }

    .experience .header-two {
        font-size: 30px;
        margin-top: 20px;
    }
}