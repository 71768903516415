.about .main-contact-form-form {
    background-color: #005DE0;
    background-image: url("https://easeitestac8f.blob.core.windows.net/uploadblob-test/blazor_background_image_two.jpeg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 130px 0px;
}

.main-contact-form {
    color: #ffffff;
}

.main-contact-form .main-heading {
    font-size: 40px;
    text-transform: capitalize;
    font-weight: 700;
    margin-bottom: 20px;
}

.main-contact-form .heading {
    text-transform: capitalize;
    font-weight: 600;
    font-size: 22px;
}

.main-contact-form .sub-heading {
    text-transform: capitalize;
    font-weight: 500;
    font-size: 20px;
}

.main-contact-form i {
    font-size: 3.5em;
    font-weight: normal;
}

.main-contact-form .content {
    padding: 30px 0;
    border-bottom: 1px solid #fff;
}

.main-contact-form .content:last-child {
    border-bottom: none;
}

@media (max-width:768px) {
    .main-contact-form .main-heading {
        font-size: 30px;
    }
}

@media (max-width: 450px) {
    .main-contact-form .content {
        text-align: center;
    }

    .main-contact-form i {
        margin-bottom: 10px;
    }
}