.position {
    position: relative;
    margin-top: 10%;
}

.about .experience {
    margin-top: 10%;
    padding-top: 10%;
}

.about .service-card {
    position: absolute;
    top: -80px;
    left: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

.about .who {
    margin: 60px 0;
    margin-top: 7%;
}

@media (max-width:768px) {
    .about .experience {
        margin-top: 90%;
    }

    .about .service-card {
        left: 30px;
    }
}

@media (max-width: 450px) {
    .about .service-card {
        position: relative;
        left: 0;
    }

    .about .experience {
        margin-top: 0;
    }
}