.services .service {
    margin-top: 8%;
}

.services .sub_heading {
    color: var(--header-color);
    font-size: 50px;
    font-weight: 600;
    width: 60%;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 80px;
}

.services .sub_heading span {
    color: var(--light);
}

.services .request {
    background-color: #010C2A;
    background-image: url(https://easeitestac8f.blob.core.windows.net/uploadblob-test/blazor_background_one.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 1;
    text-align: center;
    padding: 70px 0;
    color: #ffff;
}

.services .request .heading {
    font-weight: 600;
    margin-bottom: 20px;
    font-size: 48px;
}

.services .request .enquire {
    font-weight: 600;
    font-size: 20px;
}

.services .request .enquire a {
    color: var(--light);
    text-transform: capitalize;
    text-decoration: none;
}

.services .request .enquire a:hover {
    text-decoration: underline;
}

@media (max-width: 768px) {
    .services .sub_heading {
        font-size: 35px;
        width: 100%;
        margin-bottom: 50px;
    }

    .services .request .heading {
        font-size: 32px;
    }

    .services .request .enquire {
        font-size: 16px;
    }
}

@media (max-width: 450px) {
    .services .request .heading {
        font-size: 36px;
    }
}