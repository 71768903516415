.navbar .navbar-brand img {
    margin-right: 150px;
    max-height: 80px;
    max-width: 80px;
}

.navbar-nav .nav-item .nav-link {
    position: relative;
    font-size: 16px;
    font-weight: 500;
    padding: 0px 15px;
    color: var(--dark);
    text-transform: uppercase;
    white-space: nowrap;
    transition: all 0.3s ease-out 0s;
}

.navbar-nav .nav-item .nav-link:hover {
    color: #008DA1;
}

.navbar-nav .nav-item .nav-link:hover::after,
.navbar-nav .nav-item .nav-link.active::after {
    content: "";
    width: 30%;
    height: 2px;
    background-color: #008DA1;
    position: absolute;
    bottom: -4px;
    left: 15px;
}

.navbar-nav .nav-item.active {
    color: var(--dark);
}

.navbar .contact_search {
    display: flex;
}

.navbar .contact_search .text {
    color: #B3ABB3;
    text-transform: capitalize;
    margin-bottom: 3px;
}

.navbar .search_md {
    color: var(--text-light);
    font-size: 25px;
    display: none;
}

.navbar .call .fa-phone {
    font-size: 35px;
    margin: 12px 10px 0px 0px;
    color: var(--dark);
}

.navbar .contact_search .num a {
    color: #33302d;
    text-decoration: none;
}

.navbar .contact_search .num a:hover {
    color: var(--text-dark);
    text-decoration: underline;
}

.navbar .search i,
.navbar .shop i {
    font-size: 18px;
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    border-color: var(--dark);
    border-radius: 50%;
    padding: 10px;
    margin: 10px;
    margin-left: 20px;
    color: var(--text-dark);
}

.navbar .navbar-toggler {
    background-color: rgb(30 40 69);
    color: #fff;
    position: relative;
    left: 0;
    transition: all 5s ease;
}

.navbar .trial {
    position: relative;
}

.navbar-search-field {
    position: absolute;
    z-index: 2;
    top: 80%;
    right: 30px;
    padding: 10px 90px;
    padding-bottom: 0;
    box-shadow: 11px 10px 38px 0 rgb(46 63 99 / 15%);
    border-radius: 10px;
    background-color: #fff;
}

.navbar-search-field input[type='text'] {
    display: block;
    height: 50px;
    width: 170%;
    max-width: 170%;
    color: inherit;
    border: 0;
    border-radius: 5px;
    padding: 14px 20px 20px;
    font-family: inherit;
    font-size: 13px;
    line-height: 1.7;
    background-color: #F6F7F9;
    text-transform: unset;
    transition: 0.3s;
    margin-top: 20px;
    margin-left: -60px;
}

.navbar-search-field input[type='text']:focus {
    outline: none !important;
    outline-width: 0 !important;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
}

.navbar-search-field input[type='submit'] {
    /* display: inline-block;
    height: 51px;
    max-width: 100%;
    margin-bottom: 0;
    border-width: 0;
    border-style: solid;
    border-color: currentColor;
    border-radius: 5px;
    outline: none;
    padding: 6px 26px;
    font-family: inherit;
    font-size: 14px;
    line-height: 42px;
    letter-spacing: 0;
    cursor: pointer;
    display: none;
    color: #fff;
    transition: 0.4s; */
    display: none;
}

.navbar-search-field .sub {
    position: relative;
}

.navbar-search-field i {
    position: absolute;
    z-index: 1;
    top: 20px;
    right: -67px;
    width: 30px;
    height: 50px;
    border-radius: 5px;
    font-size: 16px;
    line-height: 50px;
    font-weight: 700;
    font-style: normal;
    text-align: center;
    transform: translate(0, 0);
    opacity: 1;
    transition: 0.4s;
    color: var(--text-dark);
    background-color: #F6F7F9;
}

@media (max-width: 991px) {
    .navbar {
        height: 100px;
    }

    .navbar .navbar-brand img {
        margin-right: 0;
    }

    .navbar #bar2 {
        color: #fff;
        background-color: rgb(30 40 69);
        font-size: 1.1em;
        position: relative;
        left: 650%;
        padding: 5px 10px;
        z-index: 500;
    }

    .navbar-nav {
        position: absolute;
    }

    .navbar-nav .nav-item .nav-link {
        padding: 5px 20px;
        color: #fff;
    }

    .navbar {
        background-color: rgb(30 40 69) !important;
    }

    .contact_search {
        display: none !important;
    }

    .navbar .search_md {
        display: inline-flex !important;
    }
}

@media (max-width: 768px) {

    .navbar-nav .nav-item .nav-link {
        display: flex;
    }

    .navbar .space {
        margin-left: 0%;
        display: none;
    }

    .navbar-nav .nav-item .nav-link:hover::after {
        width: 5%;
        bottom: 3px;
        left: 0px;
    }

    .navbar .navbar-nav {
        position: fixed;
        top: 0;
        left: 0;
        width: 90%;
        height: 150vh;
        background-color: rgb(30 40 69);
        z-index: 10;
        padding-top: 50px;
    }

    .navbar .navbar-nav .nav-item {
        color: #fff !important;
        margin-top: 15px;
        margin-left: 20px;
    }

    .navbar #bar2 {
        left: 90%;
        top: 35px;
        position: fixed;
    }

    .navbar-search-field {
        top: 100%;
        right: 10px;
        padding: 20px 90px;
        padding-bottom: 0;
    }

    .navbar-search-field i {
        right: -67px;
    }
}

@media (max-width:450px) {
    .navbar #bar2 {
        left: 88%;
    }

    .navbar-nav .nav-item .nav-link:hover::after,
    .navbar-nav .nav-item .nav-link.active::after {
        bottom: -5px;
        left: 20px;
    }

    .navbar .navbar-brand img {
        max-height: 70px;
        max-width: 70px;
        margin-bottom: 15px;
    }

    .navbar-search-field {
        top: 90%;
        right: 30px;
        padding: 10px 80px;
        padding-bottom: 0;
    }

    .navbar-search-field input[type='text'] {
        max-width: 180%;
        min-width: 180%;
        margin-top: 30px;
    }

    .navbar-search-field i {
        top: 30px;
        right: -70px;
    }

}

@media (max-width:376px) {

    .navbar-search-field {
        right: 10px;
    }
}

@media (max-width:320px) {

    .navbar-search-field {
        top: 90%;
        right: 15px;
        padding: 0px 80px;
    }

    .navbar-search-field input[type='text'] {
        position: relative;
        left: 0px;
    }

    .navbar-search-field i {
        right: -70px;
    }
}