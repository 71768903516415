.contact-details-form {
    padding: 60px 40px 60px 40px;
    background-color: #FFFFFF;
    border-radius: 10px 10px 10px 10px;
}

.contact-details-form .headers {
    text-align: center;
    margin-bottom: 25px;
}

.contact-details-form .headers .sub-heading {
    margin-bottom: 5px;
    font-weight: 600;
    color: #B7B7B7;
    text-transform: uppercase;
    font-size: 1em;
}

.contact-details-form .headers .heading {
    margin-bottom: 15px;
    font-weight: 600;
    color: #020101;
    text-transform: capitalize;
}

.contact-details-form .form-control {
    background-color: #FFFFFF;
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    border-color: #DDDDDD;
    padding: 10px;
    margin-bottom: 20px;
}

.contact-details-form #btn-block {
    width: 100%;
}

@media (max-width: 768px) {
    .contact-details-form {
        padding: 50px 0;
    }
}