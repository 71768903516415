.progressbar {
    margin-top: 40px;
}

.progressbar .info,
.progressbar .percent {
    font-weight: 600;
    font-size: 14px;
    color: var(--header-color);
    margin-top: 10px;
    margin-bottom: 20px;
}

.progressbar .single-progress {
    margin-bottom: 10px;
}

.progressbar .progress-bar-striped {
    background-color: var(--light) !important;
    height: 10px;
}