.testimonial-card {
    padding: 80px 50px 80px 50px;
}

.testimonial-card .container {
    border-bottom: 5px solid #ff5538;
    width: 75%;

}

.testimonial-card img {
    width: 200px;
    height: 200px;
    object-fit: cover;
    border-radius: 50%;
}

.testimonial-card .heading {
    font-size: 31px;
    line-height: 44px;
    font-weight: 400;
    margin-bottom: 20px;
    color: #666666;
}

.testimonial-card .name {
    font-size: 16px;
    font-weight: 600;
    color: var(--text-dark);
}

.testimonial-card .designation {
    margin-bottom: 70px;
    font-size: 15px;
    font-weight: 400;
    color: #ff5538;
}

@media (max-width:768px) {
    .testimonial-card img {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 30px auto;
    }

    .testimonial-card .heading {
        font-size: 28px;
    }

    .testimonial-card .container {
        width: 100%;
    }
}