.why-choose-us {
    background-color: #010C2A;
    padding: 120px 0;
}

@media (max-width: 768px) {
    .why-choose-us .one {
        margin-bottom: 30px;
    }

}