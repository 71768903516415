.testimonial .swiper {
    margin: 5% 0;
}

.swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: #FF5538;
}

.swiper-pagination .swiper-pagination-bullet {
    width: 13px;
    height: 14px;
}