.who {
    margin-top: 100px;
}


.who .header-one {
    font-size: 36px;
    font-weight: var(--header-weight);
    color: var(--header-color);
}

.who .text {
    color: #666666;
    width: 100%;
}

.who .border {
    border-top: 4px solid var(--dark) !important;
    width: 90%;
    margin: auto;
    margin-bottom: -2px;
}

.who .ceo {
    position: relative;
    background: #ffffff;
    box-shadow: 0px 0px 62px 0px rgb(0 0 0 / 8%);
    padding: 35px 30px 35px 60px;
    border-radius: 5px;
    position: relative;
}

.who .ceo .img img {
    border-radius: 50%;
    width: 70px;
    height: 70px;
    object-fit: cover;
    position: absolute;
    left: -20px;
}

.who .ceo .signature img {
    margin-left: -40px;
}

.who .name {
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
    color: #090d2f;
    margin-top: 10px;
}

.who .position {
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    color: #4c4d56;
}

.who ul {
    list-style: none;
}

.who li {
    padding-bottom: 1.3em;
    color: var(--text-dark);
}

.who li:before {
    content: "\f00c";
    font-family: FontAwesome;
    color: var(--dark);
    display: inline-block;
    margin-left: -1.3em;
    width: 1.3em;
}

.who .two {
    padding-left: 70px;
}

@media (max-width:768px) {
    .who ul {
        margin-top: 20px;
    }

    .who .two {
        padding-left: 15px;
    }

    .who .header-one {
        font-size: 24px;
    }

    .who .sub-header {
        margin-top: 40px;
    }
}

@media (max-width:450px) {
    .who .ceo .signature img {
        margin-left: -50px;
    }

}