.blog-card .card {
    position: relative;
    border-radius: 10px;
  height: 600px;
    word-wrap: break-word;

}

.blog-card img {
    height: 300px;
    width: 100%;
    object-fit: contain;
    margin-bottom: 15px;
}

.blog-card .type {
    background-image: linear-gradient(120deg, #54d6eb 0%, #231e32 100%);
    color: #fff;
    border: unset;
    font-size: 14px;
    border-radius: 100px;
    padding: 5px 15px;
    font-weight: 500;
    position: absolute;
    top: 50px;
    left: 30px;
}

.blog-card .organization {
    display: flex;
    justify-content: space-between;
    width:100%;
}

.blog-card .organization .name,
.blog-card .organization .comments {
    /* white-space: nowrap; */
    margin-right: 20px;
}

.blog-card .organization i {
    color: var(--dark);
}

.blog-card .card-title {
    display: block;
    margin-bottom: 14px;
    line-height: 30px;
    word-wrap: break-word;
}

.blog-card .card-title a {
    text-decoration: none;
    color: var(--text-dark);
    font-size: 22px;
    line-height: 32px;
    font-weight: 600;
}

.blog-card .card-text {
    font-size: 17px
}

.blog-card .card-footer {
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    padding: 20px 15px;
}

.blog-card .card-footer .read-more,
.blog-card .card-footer .fa {
    z-index: 1;
    font-size: 13px;
    font-weight: 600;
    vertical-align: middle;
    transition: 5000s;
    color: var(--text-dark);
    text-transform: uppercase;
    text-decoration: none;
}

.blog-card .card-footer .read-more:hover {
    text-decoration: underline;
}