.project {
    background-color: #010C2A;
    background-image: url(https://easeitestac8f.blob.core.windows.net/uploadblob-test/blazor_background_one.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 1;
    padding: 50px 0;
}

.project .icon {
    font-size: 50px;
    color: var(--light);
    font-weight: 300;
    text-align: center;
}

.project .num {
    font-size: 50px;
    color: var(--text-light);
    font-weight: bold;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.project .texts {
    color: var(--text-light);
    font-size: 14px;
    line-height: 1.7;
    font-weight: 600;
    text-align: center;
}

@media (max-width:480px) {
    .project {
        /* margin: auto; */
        width: 100%;
    }
}