.blog {
    margin-top: 6%;
    margin-bottom: 2%;
}

@media (max-width:450px) {
    .blog {
        margin-top: 20%;
        margin-bottom: 5%;
    }
}