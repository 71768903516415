.why-us {
    background-color: #F2F2F2;
    background-image: url(../../Media//hover_two_img.jpg);
    background-position: bottom right;
    background-repeat: no-repeat;
    background-size: auto;
    padding: 70px 0;
}

.why-us .heading {
    color: var(--header-color);
    font-size: 22px;
    text-transform: capitalize;
    font-weight: 600;
}

.why-us .text-dark {
    font-size: 16px;
    color: #ffffff;
}

.why-us .our-content .row {
    margin-top: 50px;
}

.why-us .fa {
    color: var(--dark);
    font-size: 3em;
}

@media (max-width:768px) {

    .why-us .sub-header {
        margin-top: 30px;
    }

    .why-us .our-content .row {
        margin-top: 30px;
    }
}

@media (max-width:450px) {
    .why-us .fa {
        display: flex;
        justify-content: center;
    }

    .why-us .heading {
        text-align: center;
        margin-top: 10px;
    }
}

@media (max-width:376px) {

    .why-us .text-dark {
        text-align: center;
        color: red;
    }
}