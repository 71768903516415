.contact-card {
    background: #ffffff;
    text-align: center;
    box-shadow: 0px 0px 100px 0px rgb(0 0 0 / 11%);
    padding: 20px;
    padding-bottom: 50px;
    border-radius: 10px;
    margin: 70px 0;
    margin-bottom: 100px;
}

.contact-card .card {
    border: none;
}

.contact-card .card-body .card-title {
    font-size: 18px;
    line-height: 36px;
    color: #333333;
    margin-top: 15px;
}

.contact-card .card-body .card-info {
    font-size: 22px;
    font-weight: 700;
    color: var(--light);
    text-decoration: underline;
    text-decoration-color: var(--dark);
}

.contact-card .icon i {
    font-size: 3em;
    padding: 30px;
    background: var(--light);
    color: #ffffff;
    border-radius: 50%;
    margin: 0 auto;
    margin-top: -115px;
}

@media (max-width:768px) {
    .contact-card {
        padding: 0px;
        max-height: 250px;
        height: 250px;
    }

    .contact-card .card-body .card-info {
        font-size: 18px;
    }

    .contact-card .icon i {
        font-size: 2.5em;
        padding: 25px;
    }
}