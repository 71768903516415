.back-to-top {
    position: fixed;
    z-index: 200;
    right: 100px;
    bottom: 20px;
    border-radius: 50%;
    border: 1px solid var(--light);
    text-align: center;
    cursor: pointer;
    padding: 15px;
    font-size: 20px;
    transform: translateX(130%);
    transition: 1s;
    background-color: var(--light);
    color: #fff;
}

.back-to-top:hover {
    background: var(--dark);
    border: 1px solid var(--dark);
}

.back-to-top:hover i {
    position: relative;
    top: -3px;
}