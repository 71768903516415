.why-us-card {
    background: #ffffff;
    padding: 50px;
    border-radius: 5px;
    position: relative;
    margin: 18px 0;
}

.why-us-card::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    background: rgba(255, 255, 255, 0.3);
    width: 100%;
    height: 100%;
    z-index: -1;
    transform: scaleY(1.1) scaleX(0.85);
    border-radius: 5px;
}

.why-us-card::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    background: rgba(255, 255, 255, 0.3);
    width: 100%;
    height: 100%;
    z-index: -1;
    transform: scaleY(1.05) scaleX(0.92);
    border-radius: 5px;
}

.why-us-card .card {
    border: none;
}

.why-us-card .card-title {
    font-size: 22px;
    line-height: 30px;
    color: var(--header-color);
    font-weight: bolder;
    text-align: center;
}

.why-us-card .card-text {
    font-size: 18px;
    line-height: 30px;
    font-weight: 400;
    text-decoration: underline;
    color: var(--text-dark);
    margin-top: 15px;
}

.why-us-card .portfolio {
    display: flex;
    justify-content: center;
}

.why-us-card img {
    width: 70px;
    height: 70px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 20px;
}

.why-us-card .name {
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
    color: #231e32;
    margin-top: 20px;
}

.why-us-card .position {
    font-size: 13px;
    line-height: 20px;
    font-weight: 400;
    color: #072f60;
    text-align: center;
}