.footer {
    background-color: #010C2A;
    scroll-snap-align: start;
    padding-top: 13%;
    margin-top: 7%;
    position: relative;
}

.footer .heading {
    color: #fff;
    font-size: 20px;
    text-transform: capitalize;
    margin-bottom: 30px;
    font-weight: bolder;
}

.footer .footer-link {
    color: #9397a0;
    text-transform: capitalize;
    margin-left: -30px;
    margin-bottom: 20px;
    display: inline-block;
}

.footer ul li {
    list-style: none;
}

.footer ul li a {
    text-decoration: none;
}

.footer .text span {
    color: var(--light);
}

.footer ul li a:hover {
    color: #ffffff;
    border-bottom: 2px solid #ffffff;
}

.footer .text {
    color: #9397a0;
    margin-top: 5px;
    margin-bottom: 20px;
    display: block;
    text-decoration: none;
}

.footer .text:hover {
    color: #ffffff;
}

.footer .text i {
    font-size: 1.3em;
}

.footer img {
    height: 150px;
    width: 120px;
    max-height: 150px;
    max-width: 120px;
}

.footer .links {
    margin-top: 45px;
}

.footer .socials i {
    margin-right: 15px;
    background-color: #15203B;
    color: #fff;
    padding: 12px;
    border-radius: 50%;
    font-size: 1.3em;
}

.footer .socials i:hover {
    background-color: #50D0EB;
}

.footer .copyright {
    border-top: 1px solid grey;
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
}

/* COLOPHON */

.colophon {
    background-image: url(../../Media/colophon.jpeg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 90%;
    margin: auto;
    border-radius: 10px;
    position: absolute;
    top: -90px;
    left: 5%;
    z-index: 1;
}

.colophon .row {
    margin: 0px 0px 0px 100px;
    --e-column-margin-right: 20px;
    --e-column-margin-left: 0px;
    padding: 80px 10px 80px 10px;
}

.colophon .heading {
    font-size: 30px;
    line-height: 40px;
    color: #ffffff;
    font-weight: bolder;
}

.colophon .num {
    color: #FFFFFF;
    font-weight: bolder;
    font-size: 22px;
    line-height: 40px;
    margin-bottom: 20px;
}

.colophon .dark .btn {
    background: var(--text-dark);
    border: 2px solid inherit;
}

.colophon .dark .btn:hover {
    border: 2px solid var(--text-dark);
    color: var(--text-dark);
    background-color: #fff;
}

@media (max-width:768px) {
    .colophon .heading {
        font-size: 28px;
    }

    .colophon .row {
        margin: 0px 0px 0px 10px;
        padding: 50px 10px 50px 10px;
    }

    .colophon .dark .btn {
        margin-left: -10px;
    }

    .footer {
        padding-top: 130px;
    }

    .footer .logo-div {
        margin-top: 40px;
        padding-left: 40px;
    }
}

@media (max-width: 450px) {
    .footer {
        padding-top: 60%;
        margin-top: 25%;
    }

    .logo-div {
        margin-left: 10px;
        margin-top: 20px;
    }

    .footer .copyright {
        text-align: center;
        flex-direction: column;
    }

    .footer .copyright .text {
        font-size: 15px;
        display: block;
    }

}