.services-card .icon {
    font-size: 2.7em;
    z-index: 2;
    color: rgb(62, 60, 60);
    margin-left: 30px;
}

.services-card .icon::before {
    content: "";
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-image: linear-gradient(-48deg, #54d6eb 0%, var(--dark) 70%);
    opacity: 0.8;
    position: absolute;
    left: 20px;
    top: 20px;
    z-index: -1;

}

.services-card .link {
    color: #231E32;
    font-size: 22px;
    font-weight: 700;
    text-decoration: none;
    text-transform: capitalize;
}

.services-card .link:hover {
    color: var(--dark);
    text-decoration: underline;
}

.services-card .card-info {
    color: var(--text-dark);
}