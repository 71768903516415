.home {
    background-color: #ffffff;
}

.home .home-hero {
    background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url("https://easeitestac8f.blob.core.windows.net/uploadblob-test/blazor_background_image_four.jpeg");
    height: 150vh;
    background-size: cover;
}

.home .shape-1 {
    width: 417px;
    height: 417px;
    background: linear-gradient(150deg, #004277 0%, var(--light) 100%);
    opacity: 0.9;
    border-radius: 50%;
    position: absolute;
    top: 200px;
    left: 50px;
    z-index: 1;
}

.home .content {
    z-index: 2;
    color: #ffffff;
    margin-top: 17.5%;
    padding: 0 100px;
    position: relative;
}

.home .sub-title {
    font-size: 16px;
    line-height: 30px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: left;
    letter-spacing: 2px;
    color: #ffffff;
    z-index: 2;
}

.home .main-title {
    font-size: 72px;
    line-height: 92px;
    font-weight: 700;
    color: #ffffff;
    margin-top: 10px;
}

.home .text {
    font-size: 18px;
    line-height: 30px;
    color: #ffffff;
    margin-top: 25px;
    margin-bottom: 60px;
    width: 45%;
}

.home-cards {
    margin-top: -7%;
}

.home .service-card {
    margin-top: -10%;
}

@media only screen and (max-width: 768px) {
    .home .shape-1 {
        width: 300px;
        height: 300px;
        left: 150px;
        margin-top: 2%;
    }

    .home .content {
        padding: 0 10px;
        margin-top: 15%;
    }

    .home .sub-title {
        font-size: 16px;
        line-height: 26px;
    }

    .home .main-title {
        font-size: 48px;
        line-height: 62px;
        font-weight: 600;
    }

    .home .text {
        width: 70%;
    }
}

@media only screen and (max-width: 575px) {
    .home .shape-1 {
        width: 250px;
        height: 250px;
        left: 50px;
        margin-top: 28%;
    }

    .home .sub-title {
        font-size: 12px;
    }

    .home .main-title {
        font-size: 38px;
        line-height: 48px;
    }

    .home .text {
        font-size: 16px;
        width: 90%;
    }

    .home .content {
        padding: 0 20px;
        margin-top: 25%;
    }
}

@media only screen and (max-width: 450px) {
    .home .shape-1 {
        left: 30px;
        margin-top: 5%;
    }
}