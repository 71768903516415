.service-cards .card {
    /* max-height: fit-content;
    min-height: 400px;
    */
    width: 300px;
    min-width: 220px;
    transition: all .5s linear;
    z-index: 1;
    /* display: flex;
    justify-content: center;
    margin: 0 auto; */
}

.service-cards .card:hover {
    background: linear-gradient(148deg, #54d6eb 0%, var(--light) 100%), url(../../../Media/hover_img.jpg);
    color: #fff !important;
}

.service-cards .card .contents {
    margin-top: 20px;
}

.service-cards .contents img {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    min-height: 120px;
    max-height: 120px;
}

.service-cards .card-title {
    font-size: 24px;
    font-weight: 700;
    width: 85%;
    margin-top: 12px;
    transition: all 0.3s linear;
    text-align: center;
    color: #231e32;
}


.service-cards .card-text {
    font-size: 14px;
    line-height: 26px;
    font-weight: 500;
    color: #4c4d56;
    margin-top: 20px;
    text-align: center;
    transition: all 0.3s linear;
}

.service-cards .card:hover .card-title,
.service-cards .card:hover .card-text {
    color: white;
    font-weight: bolder;
}

@media (max-width: 768px) {
    .service-cards .card {
        margin-top: 5%;
    }

    .service-cards .card-title {
        width: 100%;
    }
}

@media (max-width: 500px) {}