.specialty {
    background-color: #F2F2F2;
    background-image: url(https://easeitestac8f.blob.core.windows.net/uploadblob-test/blazor_background_image_three.jpeg);
    background-position: center left;
    background-repeat: no-repeat;
    background-size: auto;
    padding: 120px;
}

.specialty .info_heading {
    color: var(--header-color);
    font-size: 36px;
    font-weight: 600;
    width: 90%;
}

.specialty .info_heading span {
    color: var(--light);
}

.specialty .video {
    height: inherit;
    width: 600px;
}

.specialty .side-text {
    padding-left: 70px;
}

@media (max-width:768px) {
    .specialty {
        padding: 80px 0;
    }

    .specialty .info_heading {
        width: 100%;
        margin-top: 50px;
    }

    .specialty .side-text {
        padding: 0px;
    }
}