.clients {
    padding: 40px 0;
    background-color: #FFFFFF;
}

.clients img {
    height: auto;
    max-width: 100%;
    border: none;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    margin-bottom: 30px;
}

.clients .heading {
    font-size: 28px;
    line-height: 1.3334em;
    font-weight: 600;
    color: #717171;
    text-align: center;
    margin-bottom: 50px;
    margin-top: 30px;
}

.clients .heading span {
    color: var(--dark);
}

@media (max-width: 450px) {
    .clients .heading {
        font-size: 22px;
    }


    .clients img {
        margin: auto;
        display: flex;
        justify-content: center;
    }

}