.btn {
    display: inline-block;
    white-space: nowrap;
    color: #ffffff;
    background-color: var(--light);
    border-color: transparent;
    border: 2px solid var(--light);
    border-radius: 5px;
    font-size: 16px;
    font-weight: 700;
    height: 56px;
    line-height: 52px;
    padding: 0px 48px;
    border-style: solid;
    cursor: pointer;
    z-index: 1;
    position: relative;
    text-transform: capitalize;
    transition: all 0.3s ease 0s;
}

.btn:hover {
    box-shadow: none;
    color: var(--light);
    background-color: #fff;
}