*,
::after,
::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: "Open Sans", sans-serif;
    font-weight: normal;
    font-style: normal;
    color: #798795;
}

:root {
    --light: #13afbe;
    --dark: #002D6B;
    --text-dark: #231e32;
    --text-light: #eee;
    --header-color: #1f2327;
    --header-weight: 600;
}

.title {
    font-family: "Manrope", sans-serif;
    font-weight: 600;
    font-size: 48px;
    text-align: center;
    color: #333333;
    margin-bottom: 60px;
    padding-top: 35px;
    text-transform: capitalize;
}

.sub-header {
    font-size: 16px;
    text-transform: uppercase;
    color: var(--dark);
    padding: 7px 0px 7px 0px;
    border-radius: 20px 20px 20px 20px;
    font-weight: 600;
    font-family: Manrope;
}

.sub-header-center {
    font-size: 16px;
    text-transform: uppercase;
    color: var(--dark);
    padding: 7px 0px 7px 0px;
    border-radius: 20px 20px 20px 20px;
    text-align: center;
    font-weight: 600;
    font-family: Manrope;
}

.main-header {
    font-size: 36px;
    font-weight: var(--header-weight);
    color: var(--header-color);
    font-weight: 600;
}

.main-header-center {
    font-size: 36px;
    line-height: 1.3334em;
    font-weight: 600;
    color: var(--text-dark);
    text-align: center;
    margin-bottom: 80px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    --moz-appearance: textfield;
}

.hero {
    background-image: url("https://thepixelcurve.com/wp/techmax/wp-content/uploads/2022/01/ROW093.jpeg");
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center bottom;
    background-color: #7696b2;
    min-height: 500px;
    padding-top: 80px;
    padding-bottom: 80px;
    text-align: center;
}

.hero .heading {
    color: #ffffff;
    font-size: 60px;
    line-height: 72px;
    position: relative;
    padding-top: 10px;
    z-index: 2;
    font-weight: 700;
    text-transform: capitalize;
    margin-top: 100px;
}

.hero .heading:before {
    content: "";
    width: 75px;
    height: 75px;
    border-radius: 50%;
    background-image: linear-gradient(-48deg, #54d6eb 0%, var(--dark) 100%);
    opacity: 0.9;
    position: absolute;
    left: 570px;
    top: -10px;
    z-index: -1;
}

.hero .links p,
.hero .links p a {
    color: #ffffff;
    font-size: 16px;
    line-height: 27px;
    text-decoration: none;
    text-transform: capitalize;
}

.hero .links p a:hover {
    text-decoration: underline;
    color: var(--light);
    font-size: large;
}

.contact-form-forms {
    background-color: #005DE0;
    background-image: url("https://thepixelcurve.com/wp/techmax/wp-content/uploads/2022/01/MAX0984.jpeg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 130px 0px;
}


@media (max-width: 768px) {
    .title {
        font-size: 30px;
    }

    .sub-header,
    .sub-header-center {
        font-size: 14px;
    }

    .hero .heading:before {
        left: 200px;
    }
}

@media (max-width:450px) {

    .hero .heading {
        font-size: 32px;
    }

    .hero .heading:before {
        left: 100px;
        top: 0px;
    }
}